<template>
  <base-section class="pt-3 pb-0">
    <v-container>
      <v-row align-content="center" justify="center">
        <v-btn v-for="entry in languages" :key="entry.title" class="mr-2 mt-2 mb-2"
          :depressed="currentLanguage === entry.language" @click="changeLocale(entry.language)">
          <flag :iso="entry.flag" :squared="false" />
        </v-btn>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import i18n from '@/plugins/i18n';
export default {
  name: 'LocaleSelect',
  props: {
    background: {
      type: String,
      default: () => "transparent"
    }
  },
  data() {
    return {
      languages: [
        { flag: 'gb', language: 'en', title: 'English' },
        { flag: 'es', language: 'es', title: 'Español' }
      ],
    };
  },
  computed: {
    currentLanguage() {
      return i18n.locale;
    }
  },
  mounted() {
    if (this.$route.query.locale) {
      i18n.locale = this.$route.query.locale;
    }
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    }
  },

}
</script>
